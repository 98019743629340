import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NotFound, Gateway, BetaSignup } from "pages";

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Gateway />} />
        <Route path="/beta" element={<BetaSignup />} />

        {/* The 404 Not Found must ALWAYS Be the last route. */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
