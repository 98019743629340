import { FC } from "react";
import { EssayCard } from "./EssayCard";
import { SignupComponent } from "components";
import { emailSignup } from "api";

export const BetaSignup: FC = () => {
  const handleSubmit = async (email: string) => {
    emailSignup({
      email: email,
      successHandler: (_) => {},
      errorHandler: (error) => console.log(error),
    });
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden bg-transparent px-4">
      <div className="absolute inset-0 z-0">
        <div className="blob blob-1"></div>
        <div className="blob blob-2"></div>
        <div className="blob blob-3"></div>
      </div>
      <div className="z-10 w-full max-w-4xl mb-12">
        <EssayCard />
        <SignupComponent onSubmit={handleSubmit} />
      </div>
      <footer className="absolute bottom-4 z-20 flex flex-col items-center justify-center">
        <a
          href="mailto:info@trygateway.app"
          className="flex items-center text-sm text-gray-600 contact-link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          Contact Us
        </a>
        <p className="text-xs text-gray-500">
          &copy; 2024 Gateway. All rights reserved.
        </p>
      </footer>
      <style>{`
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

        .hero-text {
          font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: #2a2a2a;
          text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.1);
          font-size: 2rem;
          line-height: 1.2;
          max-width: 100%;
        }

        .contact-link {
          color: #gray-600;
          transition: color 0.3s ease, text-shadow 0.3s ease;
          margin-bottom: 0.5rem;
        }

        .contact-link:hover {
          color: #white;
          text-shadow: 0 0 10px rgba(255, 255, 255, 0.7),
                      0 0 20px rgba(255, 255, 255, 0.5),
                      0 0 30px rgba(255, 255, 255, 0.3);
        }

        @media (min-width: 480px) {
          .hero-text {
            font-size: 2.5rem;
          }
        }

        @media (min-width: 768px) {
          .hero-text {
            font-size: 3rem;
          }
        }

        @media (min-width: 1024px) {
          .hero-text {
            font-size: 3.5rem;
          }
        }

        .hero-text .underline {
          text-decoration: underline;
          font-weight: 700;
          text-underline-offset: 6px;
          text-decoration-thickness: 4px;
        }

        .golden-shimmer {
          position: relative;
          display: inline-block;
          color: #2a2a2a;
        }

        .golden-shimmer::before,
        .golden-shimmer::after {
          content: "";
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
          border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
          background: linear-gradient(
            45deg,
            rgba(255, 215, 0, 0.1),
            rgba(255, 215, 0, 0.3)
          );
          z-index: -1;
        }

        .golden-shimmer::before {
          filter: blur(3px);
          animation: blob-move 5s infinite alternate;
        }

        .golden-shimmer::after {
          filter: blur(2px);
          animation: blob-move 7s infinite alternate-reverse;
        }

        @keyframes blob-move {
          0% {
            border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
            transform: translate(0, 0) rotate(0deg);
          }
          100% {
            border-radius: 60% 40% 30% 70% / 50% 60% 40% 50%;
            transform: translate(2px, 2px) rotate(1deg);
          }
        }

        .launch-text {
          font-family: Georgia, serif;
          max-width: 240px;
          margin: 0 auto;
          font-size: 0.875rem;
        }

        @media (min-width: 768px) {
          .launch-text {
            font-size: 1rem;
          }
        }

        .blob {
          position: absolute;
          width: 150%;
          height: 150%;
          filter: blur(40px);
          mix-blend-mode: screen;
          opacity: 0.7;
        }
        .blob-1 {
          top: -25%;
          left: -25%;
          background: radial-gradient(
            circle,
            rgba(255, 100, 100, 0.8) 0%,
            rgba(255, 100, 100, 0) 70%
          );
          animation: moveBlob1 10s infinite alternate;
        }
        .blob-2 {
          top: 25%;
          right: -25%;
          background: radial-gradient(
            circle,
            rgba(100, 200, 255, 0.8) 0%,
            rgba(100, 200, 255, 0) 70%
          );
          animation: moveBlob2 15s infinite alternate;
        }
        .blob-3 {
          bottom: -25%;
          left: 25%;
          background: radial-gradient(
            circle,
            rgba(150, 255, 100, 0.8) 0%,
            rgba(150, 255, 100, 0) 70%
          );
          animation: moveBlob3 20s infinite alternate;
        }
        @keyframes moveBlob1 {
          0%,
          100% {
            transform: translate(0, 0) scale(1);
          }
          25% {
            transform: translate(5%, 10%) scale(1.1);
          }
          50% {
            transform: translate(10%, 5%) scale(0.9);
          }
          75% {
            transform: translate(5%, 15%) scale(1.05);
          }
        }
        @keyframes moveBlob2 {
          0%,
          100% {
            transform: translate(0, 0) scale(1);
          }
          25% {
            transform: translate(-10%, 5%) scale(1.1);
          }
          50% {
            transform: translate(-5%, 10%) scale(0.9);
          }
          75% {
            transform: translate(-15%, 5%) scale(1.05);
          }
        }
        @keyframes moveBlob3 {
          0%,
          100% {
            transform: translate(0, 0) scale(1);
          }
          25% {
            transform: translate(10%, -5%) scale(1.1);
          }
          50% {
            transform: translate(5%, -10%) scale(0.9);
          }
          75% {
            transform: translate(15%, -5%) scale(1.05);
          }
        }
      `}</style>
    </div>
  );
};
