import { FC, useState } from "react";

export const EssayCard: FC = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="essay-card">
      <h1 className="essay-card__hero-text">
        A new copilot for{" "}
        <span className="essay-card__hero-text--underline">your health</span>.
      </h1>
      {/* <h2 className="essay-card__mission-title">Our Mission</h2> */}
      <p className="essay-card__paragraph mt-10">
        Hi, I'm Abe 👋. Thank you for considering to join the Gateway beta. My
        mom is a type-II diabetic and I recently learned that I am pre-diabetic.
        I'm building Gateway for us, but think that it could help some subset of
        the other 100 million people in the US living with pre-diabetes.
      </p>
      <p className="essay-card__paragraph essay-card__paragraph--bold">
        Our mission is to develop a new copilot for your health that learns
        about your body and tastes to proactively suggest healthy choices at the
        time you are making those decisions…whether that is at a restaurant, in
        the grocery store, or simply when you are grazing the fridge.
      </p>
      <p className="essay-card__paragraph italic essay-card__paragraph--bold">
        Or (more simply): We use your continuous glucose monitor (CGM) + AI to
        help you live better.
      </p>
      <p className="essay-card__paragraph">
        Today, we are living in a health epidemic.{" "}
        <strong>More than 38%</strong> of the US population is living with
        pre-diabetes and <strong>69% are</strong> either overweight or obese.
        That's more than <strong>100 million</strong> and{" "}
        <strong>230 million</strong> people respectively. Let that sink in.
      </p>
      <p className="essay-card__paragraph">
        For the vast majority of people in the US, it is not their fault. Our
        infrastructure is setting them up to fail. Why are cities built for cars
        instead of bikes? Why is a salad four times as expensive as a burger?
        Why is the average diet in the US one that makes the ludicrous numbers
        above possible?
      </p>
      <p className="essay-card__paragraph">
        In the coming years, we are going to see an explosion of continuous
        glucose monitors (CGMs). CGMs are tools that measure the level of
        glucose in your body. They used to primarily be reserved for diabetics
        to help manage their insulin levels. However, recent trends are
        indicating their widespread utility in enabling users to achieve their
        health outcomes. While diabetics have a medical necessity to spend time
        to understand the powerful interplay between eating and resulting
        glucose levels,{" "}
        <strong>
          what about the rest of us? Could we build something that helps change
          our habits?
        </strong>
      </p>
      <p className="essay-card__paragraph">
        Already, stiff competition has forced the price of CGMs down. Dexcom
        even released a version this summer that does not require a
        prescription. This is going to be a game changer. They know that the
        most vulnerable and impacted population, which is a large swath of the
        US, cannot afford the current market offerings. On top of that, every
        big tech company has been pining for the holy grail of wearable sensors:
        a non-invasive CGM embedded in a smartwatch. When that happens (likely
        in the next 2-5 years), everyone is going to begin to have access to
        this increasingly important data feed.
      </p>
      <p className="essay-card__paragraph essay-card__paragraph--bold">
        And, it's our goal to make that data actionable!
      </p>
      <p className="essay-card__paragraph">
        The existing metabolic health apps out there (Levels, UnderMyFork,
        Nutrisense etc.) are cost prohibitive and only surface information. We
        want to make a coach that lives in your pocket, helping you make healthy
        choices at the time you are making decisions. We don't think that these
        apps should only be for the people who can afford them. Thanks for being
        interested in being an early user! You will play a crucial part in our
        journey to empower millions of people to live healthier lives.
      </p>
      <style>{`
        .essay-card {
          background-color: rgba(243, 244, 246, 0.95);
          border-radius: 1rem;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15),
            0 1px 3px rgba(0, 0, 0, 0.13);
          padding: 1rem;
          margin: 2rem auto;
          width: 90%;
          max-width: 2000px;
          position: relative;
          z-index: 10;
          backdrop-filter: blur(5px);
          overflow-y: auto;
          max-height: 70vh;
        }

        .essay-card__hero-text {
          font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: #2a2a2a;
          text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.1);
          font-size: 2rem;
          line-height: 1.2;
          max-width: 100%;
          margin-bottom: 0.25rem;
          text-align: left;
        }

        .essay-card__hero-text--underline {
          text-decoration: underline;
          font-weight: 700;
          text-underline-offset: 6px;
          text-decoration-thickness: 4px;
          position: relative;
          display: inline-block;
          color: #2a2a2a;
        }

        .essay-card__hero-text--underline::before,
        .essay-card__hero-text--underline::after {
          content: "";
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
          border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
          background: linear-gradient(
            45deg,
            rgba(255, 215, 0, 0.1),
            rgba(255, 215, 0, 0.3)
          );
          z-index: -1;
        }

        .essay-card__hero-text--underline::before {
          filter: blur(3px);
          animation: blob-move 5s infinite alternate;
        }

        .essay-card__hero-text--underline::after {
          filter: blur(2px);
          animation: blob-move 7s infinite alternate-reverse;
        }

        .essay-card__launch-text {
          font-family: Georgia, serif;
          max-width: 240px;
          font-size: 0.875rem;
          color: #000000;
          margin-bottom: 1.5rem;
          text-align: left;
        }

        .essay-card__mission-title {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
          text-align: left;
        }

        .essay-card__paragraph {
          font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          line-height: 1.6;
          margin-bottom: 1rem;
          // text-align: justify;
        }

        .essay-card__paragraph--bold {
          font-weight: bold;
        }

        .essay-card__read-more {
          transition: color 0.2s ease;
          background: none;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
        }

        .essay-card__read-more:hover {
          color: #facc15;
        }

        @keyframes blob-move {
          0% {
            border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
            transform: translate(0, 0) rotate(0deg);
          }
          100% {
            border-radius: 60% 40% 30% 70% / 50% 60% 40% 50%;
            transform: translate(2px, 2px) rotate(1deg);
          }
        }

        @media (min-width: 480px) {
          .essay-card__hero-text {
            font-size: 2.5rem;
          }
        }

        @media (min-width: 768px) {
          .essay-card {
            padding: 2.5rem;
          }
          .essay-card__hero-text {
            font-size: 3rem;
          }
          .essay-card__launch-text {
            font-size: 1rem;
          }
        }

        @media (min-width: 1024px) {
          .essay-card {
            padding: 3rem;
          }
          .essay-card__hero-text {
            font-size: 3.5rem;
          }
        }
      `}</style>
    </div>
  );
};
