import { Error } from "types";
import { request } from "api";

export interface EmailSignupResponse {
  success: boolean;
}

export interface EmailSignupRequest {
  email: string;
  successHandler: (data: EmailSignupResponse) => void;
  errorHandler: (error: Error) => void;
}

export const emailSignup = async (
  params: EmailSignupRequest,
): Promise<void> => {
  const { email, successHandler, errorHandler } = params;

  const { data, error } = await request<EmailSignupResponse>({
    path: "/email/signup",
    data: {
      email: email,
    },
  });

  if (error) {
    errorHandler(error);
    return;
  }

  if (data) {
    successHandler(data);
    return;
  }
};
