import React, { FC, useState, FormEvent } from "react";

export interface SignupComponentProps {
  onSubmit: (email: string) => void;
}

export const SignupComponent: FC<SignupComponentProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [shake, setShake] = useState(false);
  const [showHelper, setShowHelper] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      onSubmit(email);
      setIsSubmitted(true);
      setTimeout(() => {
        setEmail("");
        setShowHelper(false);
        setIsSubmitted(false);
      }, 3000);
    } else {
      setIsValid(false);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(isValidEmail(newEmail));
    setShowHelper(newEmail.length > 0);
  };

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <div className="w-full max-w-md mx-auto mt-4">
      <form
        onSubmit={handleSubmit}
        className={`relative ${shake ? "animate-subtle-shake" : ""}`}
        noValidate
      >
        <div className="relative flex">
          <input
            type="email"
            value={email}
            onChange={handleInputChange}
            placeholder="beta@trygateway.app"
            className={`flex-grow px-4 py-3 rounded-l-lg border-2 border-r-0 ${
              isValid ? "border-gray-200" : "border-gray-500"
            } outline-none transition-all duration-200 text-gray-800 placeholder-gray-400`}
            required
            disabled={isSubmitted}
          />
          <button
            type="submit"
            className={`font-medium transition-all duration-300 ease-in-out flex items-center justify-center shimmer-button ${
              isSubmitted ? "bg-yellow-500 text-white" : "bg-black text-white"
            } px-6 rounded-r-lg`}
            style={{ width: isSubmitted ? "160px" : "auto" }}
          >
            <span
              className={`flex items-center transition-all duration-300 ${
                isSubmitted ? "opacity-0" : "opacity-100"
              }`}
            >
              Get on the list
              <svg
                className="w-5 h-5 ml-2"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={`flex items-center absolute transition-all duration-300 ${
                isSubmitted ? "opacity-100" : "opacity-0"
              }`}
            >
              Thank you!
              <svg
                className="w-5 h-5 ml-2"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 4L12 14.01L9 11.01"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
        <div
          className="mt-1 transition-opacity duration-200"
          style={{
            opacity: showHelper ? 1 : 0,
          }}
        >
          <p className={"text-sm text-gray-500 ml-1"}>
            {isValid ? "" : "Enter a valid email address"}
          </p>
        </div>
      </form>
      <style>{`
        @keyframes subtle-shake {
          0%, 100% { transform: translateX(0); }
          25% { transform: translateX(-2px); }
          75% { transform: translateX(2px); }
        }
        .animate-subtle-shake {
          animation: subtle-shake 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        }

        .shimmer-button {
          position: relative;
          overflow: hidden;
        }

        .shimmer-button::before {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(
            to bottom right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: rotate(45deg) translateY(-100%);
          transition: all 0.5s ease;
        }

        .shimmer-button:hover::before {
          transform: rotate(45deg) translateY(100%);
        }
      `}</style>
    </div>
  );
};
